import Image from "next/image";
import Link from "next/link";
import React, { type LegacyRef, useEffect, useRef } from "react";

import { cn } from "~/lib/utils";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import ContactUs from "./contact-us";

export default function Hero() {
  return (
    <MarketingShell showLinks>
      {/* <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
        <svg
          className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div> */}
      <HeroComponent />
      <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
        <svg
          className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </MarketingShell>
  );
}

export function MarketingShell({
  children,
  showLinks = false,
}: {
  children: React.ReactNode;
  showLinks?: boolean;
}) {
  return (
    <div className="bg-background text-foreground">
      <div className="relative isolate px-6 pt-14 lg:px-8">{children}</div>
    </div>
  );
}

function HeroComponent() {
  const svgRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const screenWidth = window.innerWidth ?? 0;
      const isLgPlus = screenWidth > 1024;
      const isMdToLg = screenWidth > 768 && screenWidth < 1024;
      const isMdOrSmaller = screenWidth < 768;
      const coordsModifier = isLgPlus
        ? 2
        : isMdToLg
        ? 3
        : isMdOrSmaller
        ? 4
        : 2;
      const scrollY = window.scrollY ?? 0;
      const xCoords = scrollY / coordsModifier;
      const yCoords = (3 * scrollY) / (coordsModifier * coordsModifier);
      if (svgRef.current) {
        const transformValue = `translate3d(${xCoords}px, ${-yCoords}px, 0)`;
        svgRef.current.style.transform = transformValue;
      }
    };

    // Optionally throttle or debounce this handler if necessary
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={cn(
        "mx-auto max-w-8xl overflow-hidden",
        "mx-auto px-6 pb-64 sm:pb-80 lg:px-0 lg:pb-80",
        "-translate-y-10x transform transition-transform duration-1000",
        "relative animate-rise",
      )}
    >
      <div
        className={cn(
          "pointer-events-none absolute right-0 top-0 hidden h-3/5 w-[10%] bg-gradient-to-r from-white/0 via-white/20 to-white/100 lg:block",
          "dark:hidden",
        )}
      ></div>

      {/*  */}
      <div
        ref={svgRef as LegacyRef<HTMLDivElement>}
        className="absolute bottom-0 right-0 -z-40 transition-none duration-0 lg:right-0"
        // className="absolute bottom-0 right-0 -z-40 transition-transform duration-200 ease-out lg:right-0"
        // className="absolute bottom-0 right-0 -z-40"
        // style={{
        //   transform: `translateX(${coords}px) translateY(-${coords}px)`,
        // }}
      >
        <Image
          className="-z-10 md:w-[680px] lg:w-[60vw] lg:max-w-[900px]"
          // className="-z-10 scale-150 animate-infscroll md:w-[680px] lg:w-[90vw] lg:max-w-[900px]"
          src="/common/FT3.svg"
          // src={"/common/OverallFlow.svg"}
          width={2000}
          height={2000}
          alt="ft.svg"
        />
      </div>

      <div className="lg:mx-12 lg:w-2/3">
        <div className="mb-4 mt-2 flex sm:mb-8">
          <div
            // ref={isporTitleRef}
            className="relative rounded-full px-3 py-1 text-xs leading-6 text-muted-foreground ring-1 ring-foreground/10 hover:ring-foreground/20 dark:text-slate-300"
          >
            Meet EasySLR team at ISPOR 2024 in Barcelona.{" "}
            <Link href="/welcome/ispor-europe-2024" className="font-semibold text-foreground">
              <span className="absolute inset-0" aria-hidden="true" />
              Read more <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
        <div className="">
          <h1
            // ref={titleRef}
            className={cn(
              "text-4xl font-semibold tracking-tight text-foreground sm:text-7xl",
            )}
          >
            Fly Through Reviews
          </h1>
          <p
            // ref={subtitleRef}
            className="mt-6 text-lg leading-8 text-slate-700 md:max-w-3xl dark:text-slate-300"
          >
            Built on advanced Large Language Model (LLM) research, deep domain
            expertise, and a focus on superior user experience, EasySLR
            simplifies your systematic review process.
          </p>
          <div
            // ref={getStartedRef}
            className="mt-10 flex gap-x-6"
          >
            <div>
              <Dialog>
                <DialogTrigger asChild>
                  <Link href="/auth/signup" passHref>
                    <Button className="flex items-center gap-x-2">
                      Claim Free Project
                    </Button>
                  </Link>
                </DialogTrigger>
                {/* <DialogContent className="sm:max-w-2xl">
                  <ContactUs />
                </DialogContent> */}
              </Dialog>
              <div className="mt-1 text-center text-xs leading-5 text-muted-foreground">
                No credit card required
              </div>
            </div>

            <a
              href="#features"
              className="flex h-10 items-center justify-between gap-1 text-sm font-semibold leading-6 text-foreground"
            >
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
